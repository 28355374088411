import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import MainTitle from '../components/MainTitle'
import ArticlesList from '../components/ArticlesList'


const Blog = ({ data: { directusBlog: blog, allDirectusArtigo: articles } }) => (
    <Layout>
        <SEO title="Blog" />

        <MainTitle
            content={ blog.titulo } />

          <ArticlesList data={ articles.nodes } />

    </Layout>
)

export const query = graphql`
  {
    directusBlog {
      titulo
    }
    allDirectusArtigo {
      nodes {
        id
        titulo
        owner {
          first_name
          last_name
        }
        conteudo
        url
      }
    }
  }
`

export default Blog
