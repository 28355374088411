import React from 'react'
import propTypes from 'prop-types'
import { Link } from 'gatsby'

import './article.scss'

const ArticleBlock = ({ ...props }) => {
    function createMarkup() {
        return {__html: props.content.slice(0, 200).concat('...')}
    }

    return(
        <div className="article-block">
            <h4>{ props.title }</h4>

            <p className="article-block__author">
                Escrito por { props.first_name } { props.last_name }</p>

            <div className="article-block__description">
                <div dangerouslySetInnerHTML={createMarkup()} />
            </div>

            <div className="article-block__button">
                <Link to={ `/blog/${ props.url }` }>Leia o artigo</Link>
            </div>
        </div>
    )
}

ArticleBlock.propTypes = {
    url: propTypes.string,
    title: propTypes.string,
    first_name: propTypes.string,
    last_name: propTypes.string,
    content: propTypes.string,
}

export default ArticleBlock
