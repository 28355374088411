import React from 'react'
import Fade from 'react-reveal/Fade'
import ArticleBlock from '../ArticleBlock'

import './articles-list.scss'

const ArticlesList = ({ ...props }) => {
    return(
        <div className="articles-list">
            <Fade bottom cascade>
                <div className="articles-list__container">
                    <div className="articles-list__columns">
                        { props.data.map((item) => (
                            <div key={ item.id }>
                                <ArticleBlock
                                    url={item.url}
                                    title={ item.titulo }
                                    first_name={ item.owner.first_name }
                                    last_name={ item.owner.last_name }
                                    content={ item.conteudo } />
                            </div>
                        ))}
                    </div>
                </div>
            </Fade>
        </div>
    )
}


export default ArticlesList
